import React from "react"
import { Layout } from "../../components/Layout"
import { WatchWebinarRecording } from "../../commonComponents"
import { WebinarMiddleSection } from "../../WebinarMiddleSection"
import "./styles.css"
import { Helmet } from "react-helmet"

const formId = "9ad8978f-c351-4609-9708-2754eb04c955"

export default function SdExpoPanel() {
  const styles = {
    fontContact: {
      color: "#fff",
      fontFamily: "DM Sans",
    },
    alignContent: {
      display: "flex",
      alignItems: "center",
    },
  }
  const getDescription = () => {
    return <p>Description</p>
  }
  return (
    <Layout
      title="Boost Renewal, Avert At-Risk Account via AI Powered Support Automation"
      keywords="webinar, proactive AI customer support, customer support automation, Improve CSAT, Improve MTTR"
      description="Using AI to instantly link product details with customer support inquiries, reveal the root causes of problems, minimise escalations & enhance CSAT. Click to learn more!"
    >
      <Helmet>
        <link rel="canonical" href="https://irisagent.com/sd-expo-panel/" />
      </Helmet>
      <section
        style={{
          paddingTop: "40px",
        }}
      >
        <section
          id=" webinar-bg"
          className=" webinar-bg"
          style={{
            // backgroundImage: 'url("/img/resources/post 13.jpeg")',
            minHeight: "620px",
          }}
        >
          <div className=" webinar-container" style={styles.fontContact}>
            <img
              className="webinarButton"
              src="/img/WebinarButton.svg"
              alt=""
            />

            <h1 className="webinar-title" style={styles.fontContact}>
              SupportDriven Expo <br />
              Panel: Getting Started <br />
              with AI for <br />
              Support Operations
            </h1>

            <div className="subtitle-web" style={{ display: "flex" }}>
              <img
                className="calendar"
                src="/img/calendar21Oct.svg"
                alt=""
                width={360}
              />
              <p className="time">Oct 21, 2022 9:00 AM PT</p>
            </div>
            <WatchWebinarRecording></WatchWebinarRecording>
          </div>
          <div className="webinarHeader">
            <img
              src="/img/WebinarHeader.svg"
              alt=""
              className="webinarHeaderImg"
            />
          </div>
        </section>
      </section>

      <WebinarMiddleSection
        mainImage="/img/WebinarSpeakers-sd-expo.svg"
        // imageTextMiniature="/img/WebinarSpeakers.svg"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={<>Webinar Details</>}
        descriptionTextContent={<>
        Frontline support representatives have the unenviable task of responding to issues and escalations from customers in the shortest possible time. Often they start with little information about why the issue occurs and have to establish context and assess the root cause - often traced to core product related issues.
        <br/><br/>
        Product related issues are unfortunately the blind spot in Support Operations.  AI powered automated tagging and correlation to product issues as well as proactive escalation management can mitigate this.  Learn from our panelists on how they implemented the right processes and AI-powered support operation automation to help solve for this blind-spot and turn support agents into product experts that drive faster resolution times and preempt escalations.
        </>}
      />
      {/* <!-- End Middle Section --> */}
    </Layout>
  )
}
